import React, { useEffect, useState } from 'react';
import LandingPage from "../components/landingpage";

import SEO from "../components/seo";
import ContainerForPages from "../components/containerForPages";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import Container from "../components/container";
import { getPageUrlOutOfSanityReference, toPlainText } from "../lib/helpers";

const browser = typeof window !== "undefined" && window;

export const query = graphql`
query NotFoundPageQuery {
  post: sanityLandingPage(slug: { current: { eq: "404-page" } }) {
    id
    title
    subtitle
    theme
    _type
    slug {
      current
    }
    subfolder {
      folder
    }
    showConfetti
    isPrivatePage
    _rawExcerpt(resolveReferences: {maxDepth: 5})
    content {
      ... on SanityCourseTitle {
        _key
        _type
        courseId
        fontColour
        desktopImage {
          ...SanityImage
          alt
        }
        mobileImage {
          ...SanityImage
          alt
        }
        tabletImage {
          ...SanityImage
          alt
        }
        title
        _rawIntroPara(resolveReferences: {maxDepth: 5})
        _rawIntroTitle(resolveReferences: {maxDepth: 5})
        showButton
        buttonText
        buttonLink {
          ... on SanityInformation {
            id
            subfolder {
              folder
            }
            slug {
              current
            }
            _type
          }
          ... on SanityLandingPage {
            id
            slug {
              current
            }
            subfolder {
              folder
            }
            _type
          }
          ... on SanityLinkCategory {
            id
            url
            _type
          }
          ... on SanityPost {
            id
            slug {
              current
            }
            _type
          }
        }
      }
      ... on SanityAvailableCourses {
        _key
        _type
        title
        backgroundColour
      }
      ... on SanityTabsTextboxBlock {
        _key
        _type
        tabs {
          title
          tabParagraph
          _rawTextParagraph
          fullSizeImage {
            ...SanityImage
            alt
          }
          image {
            ...SanityImage
            alt
          }
          imagePosition
        }
      }
      ... on SanityDropdownWidget {
        _key
        _type
        items {
          title
          _rawText
        }
      }
      ... on SanitySocialMediaPosts {
        _key
        _type
        socialPost {
          postId
          postContent
          socialPlatform {
            title
          }
        }
      }
      ... on SanityTwitterBlock {
        _key
        _type
        title
      }
      ... on SanityLandingSearchBlock {
        _key
        _type
        title
      }
      ... on SanityBestBuysTable {
        _key
        _type
        title
        calculatorAssumptionsModel {
          assumptionRow
          lumpSum
        }
        costInBBTable
        showAdvancedFilters
        sortOptions
        defaultSortOption
        defaultLayout
        enableComparison
        sponsoredProvider {
          dashboardId
        }
        comparisonPageReference {
          ... on SanityInformation {
            _type
            slug {
              current
            }
            subfolder {
              folder
            }
          }
          ... on SanityLinkCategory {
            _type
            url
          }
          ... on SanityPost {
            slug {
              current
            }
            _type
          }
          ... on SanityQuestionPost {
            _type
            slug {
              current
            }
          }
          ... on SanityLandingPage {
            id
            subfolder {
              folder
            }
            slug {
              current
            }
            _type
          }
        }
      }
      ... on SanityRecProviders {
        _type
        title
        position
        companies {
          companyName
          companyLogo {
            ...SanityImage
            alt
          }
          inANutshell
          inANutshellVisible
          ourRating
          yourRating
          partner
          dashboardId
          reference {
            ... on SanityInformation {
              _type
              slug {
                current
              }
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              _type
              url
            }
            ... on SanityPost {
              slug {
                current
              }
              _type
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
            ... on SanityLandingPage {
              id
              subfolder {
                folder
              }
              slug {
                current
              }
              _type
            }
          }
          reviewReference {
            ... on SanityInformation {
              _type
              slug {
                current
              }
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              _type
              url
            }
            ... on SanityPost {
              slug {
                current
              }
              _type
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
            ... on SanityLandingPage {
              id
              subfolder {
                folder
              }
              slug {
                current
              }
              _type
            }
          }
        }
      }
      ... on SanityTipChooser {
        tipChoose {
          title
          description {
            _rawChildren
          }
          tipElements {
            _rawTipText(resolveReferences: {maxDepth: 10})
          }
        }
        _type
      }
      ... on SanityBreadcrumb {
        _key
        _type
        breadcrumbs {
          title
          linkReference {
            ... on SanityInformation {
              _type
              slug {
                current
              }
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              _type
              url
            }
            ... on SanityPost {
              slug {
                current
              }
              _type
            }
            ... on SanityQuestionPost {
              _type
              slug {
                current
              }
            }
            ... on SanityLandingPage {
              id
              subfolder {
                folder
              }
              slug {
                current
              }
              _type
            }
          }
        }
      }
      ... on SanityPopularConversationsBlock {
        _key
        _type
        title
        queryString
        backgroundColor
        arrows
        autoscroll
        layout
      }
      ... on SanityBigBlockGroup {
        _key
        _type
        title
        styles
        layout
        arrows
        autoscroll
        smoothAutoscroll
        smoothAutoscrollSpeed
        bigBlockItems {
          linkText
          _rawIntroPara(resolveReferences: {maxDepth: 5})
          title
          mainImage {
            ...SanityImage
            alt
          }
          reference {
            ... on SanityInformation {
              title
              _type
              slug {
                current
              }
              subfolder {
                folder
              }
            }
            ... on SanityLinkCategory {
              id
              url
              _type
            }
            ... on SanityPost {
              title
              subtitle
              _type
              slug {
                current
              }
            }
            ... on SanityQuestionPost {
              title
              _type
              slug {
                current
              }
            }
            ... on SanityLandingPage {
              id
              subfolder {
                folder
              }
              slug {
                current
              }
              _type
            }
          }
        }
      }
      ... on SanityHeroSection {
        _key
        _type
        styles
        title
        linkText
        
        _rawThankYouText(resolveReferences: {maxDepth: 5})
        videoUrl
        _rawIntroPara(resolveReferences: {maxDepth: 5})
        alignment
        signupRedirectLink
        downloadUrl
        brazeCustomAttributes {
          customAttributeKey
          customAttributeValue
        }
        mainImage {
          ...SanityImage
          alt
        }
        reference {
          ... on SanityInformation {
            _type
            slug {
              current
            }
            subfolder {
              folder
            }
          }
          ... on SanityPost {
            _type
            slug {
              current
            }
          }
          ... on SanityQuestionPost {
            id
            _type
            slug {
              current
            }
          }
          ... on SanityLinkCategory {
            id
            url
            _type
          }
          ... on SanityWidgetCategory {
            id
            title
            _type
          }
          ... on SanityLandingPage {
            id
            subfolder {
              folder
            }
            slug {
              current
            }
            _type
          }
        }
      }
      ... on SanityHeaderBlock {
        _key
        _type
        title
        linkText
        _rawIntroPara(resolveReferences: {maxDepth: 5})
        alignment
        desktopImage {
          ...SanityImage
          alt
        }
        tabletImage {
          ...SanityImage
          alt
        }
        mobileImage {
          ...SanityImage
          alt
        }
        reference {
          ... on SanityInformation {
            _type
            slug {
              current
            }
            subfolder {
              folder
            }
          }
          ... on SanityPost {
            _type
            slug {
              current
            }
          }
          ... on SanityQuestionPost {
            id
            _type
            slug {
              current
            }
          }
          ... on SanityLinkCategory {
            id
            url
            _type
          }
          ... on SanityWidgetCategory {
            id
            title
            _type
          }
          ... on SanityLandingPage {
            id
            subfolder {
              folder
            }
            slug {
              current
            }
            _type
          }
        }
      }
      ... on SanityTitleSection {
        _key
        _type
        title
        styles
        alignment
        linkText
        reference {
          ... on SanityInformation {
            _type
            id
            slug {
              current
            }
            subfolder {
              folder
            }
          }
          ... on SanityPost {
            _type
            slug {
              current
            }
          }
          ... on SanityQuestionPost {
            id
            _type
            slug {
              current
            }
          }
          ... on SanityLinkCategory {
            id
            url
            _type
          }
          ... on SanityWidgetCategory {
            id
            title
            _type
          }
          ... on SanityLandingPage {
            id
            subfolder {
              folder
            }
            slug {
              current
            }
            _type
          }
        }
        _rawIntroPara(resolveReferences: {maxDepth: 5})
      }
      ... on SanityLatestArticlesBlock {
        articleCount
        _type
         CategoryMultiSelect {
          _id
          title
        }
      }
      ... on SanityCalcReference {
        _type
        href
      }
      ... on SanityFilteredQuestionsBlock {
        _type
        title
      }
      ... on SanityAdvisersList {
        title
        _type
      }
      ... on SanityFundList {
        title
        _type
      }
      ... on SanitySmallSpacerBlock {
        _key
        _type
        title
      }
      ... on SanityWidgetSelection {
        _key
        _type
        widgets {
          title
          adZone
          mobileAdZone
          _type
          _rawBody
          _rawMainImage
          linkReference {
            ... on SanityInformation {
              id
              title
              subfolder {
                folder
              }
              slug {
                current
              }
              _type
            }
            ... on SanityLandingPage {
              id
              slug {
                current
              }
              subfolder {
                folder
              }
              _type
              title
            }
            ... on SanityLinkCategory {
              id
              url
              _type
            }
            ... on SanityPost {
              id
              _type
              slug {
                current
              }
              title
            }
            ... on SanityQuestionPost {
              id
              _type
              slug {
                current
              }
              title
            }
          }
          codeSnippet {
            children {
              text
            }
          }
          widgetType {
            title
          }
        }
      }
    }
    canonicalTagOverride
  }
  advList: allSanityPeople(
    filter: {publishedAt: {ne: null}, showAdviser: {eq: true}}
    sort: {order: DESC, fields: [answeredQuestionsRank, dateAnsweredRank]}
  ) {
    nodes {
      dashboardId
      name
      email
      jobTitle
      averageRating
      slug {
        current
      }
      image {
        ...SanityImage
        alt
      }
      _rawBio(resolveReferences: {maxDepth: 5})
      _rawSummary(resolveReferences: {maxDepth: 5})
      id
      femaleAdviser
      isVisibleAdviser
      feeStructure
      isVisibleAdviser
      hideFeeStructure
      location {
        location
      }
      qualification {
        qualification
      }
      specialisations {
        specialisation
      }
      showAdviser
      numberOfLikes
      averageRating
      numberOfRatings
      publishedAt
      answeredQuestionsRank
      dateAnsweredRank
      latitude
      longitude
      radius
    }
  }
  dictionary: allSanityDictionary {
    nodes {
      key
      value
    }
  }
}
`;


const NotFoundPage = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const getPageUrl = "/404"

  post.dictionary = data && data.dictionary;
  post.advList = data.advList;

  const [isMount, setMount] = useState(false);

  useEffect(() => {
    setMount(true);
  }, [])

  if (!isMount) {
    return (
      null
    )
  }


  try {
    return (
      <Layout isCoursePage={post.theme == "Boring Money Campus theme" ? true : false}>
        {errors && <SEO title="GraphQL Error" />}
        {post && (
          <SEO
            title={post.title || "Boring Money"}
            description={toPlainText(post._rawExcerpt)}
            image={post.mainImage}
            canonical={!post.canonicalTagOverride && getPageUrl}
            canonicalTagOverride={post.canonicalTagOverride}
          />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <LandingPage {...post} />}
      </Layout>
    );
  } catch (e) {
    console.log("LandingPageTemplate", e);
    return (null);
  }


};

export default NotFoundPage;
